﻿@import "colours.less";
@import "sizes.less";

@facebookW: 40px;
@facebookH: 40px;
@linkedinW: 40px;
@linkedinH: 40px;
@xW: 40px;
@xH: 40px;
@youtubeW: 40px;
@youtubeH: 40px;
@instagramW: 40px;
@instagramH: 40px;
@tiktokW: 40px;
@tiktokH: 40px;
@threadsW: 40px;
@threadsH: 40px;

footer {
    background-color: @grey;
    color: @white;
    font-size: 0.8rem;
    text-align: center;
    padding: 30px 10px;

    a {
        color: @white;
    }

    li {
        display: inline-block;
        margin: 0 0.3rem;
    }

    #footerSocialMediaLinks {
        line-height: 2.8rem;

        li {
            vertical-align: middle;
            margin: 0 0 !important;

            a {
                background: transparent url() no-repeat center center;
                background-size: 100% 100%;
                text-indent: -1000px;
                overflow: hidden;
                display: block;
            }
        }

        .facebook a {
            background-image: url(../images/facebook-wt.svg);
            width: @facebookW;
            height: @facebookH;
        }

        .linkedin a {
            background-image: url(../images/linkedin-wt.svg);
            width: @linkedinW;
            height: @linkedinH;
        }

        .x a {
            background-image: url(../images/x-wt.svg);
            width: @xW;
            height: @xH;
        }

        .youtube a {
            background-image: url(../images/youtube-wt.svg);
            width: @youtubeW;
            height: @youtubeH;
        }

        .instagram a {
            background-image: url(../images/instagram-wt.svg);
            width: @instagramW;
            height: @instagramH;
        }

        .tiktok a {
            background-image: url(../images/tik-tok-white.svg);
            width: @tiktokW;
            height: @tiktokH;
        }

        .threads a {
            background-image: url(../images/threads-wt.svg);
            width: @threadsW;
            height: @threadsH;
        }
    }

    #footerLogos {
        margin: 1.8rem 0 3rem;

        ul {
            white-space: nowrap;

            @media screen and (min-width: 400px) {
                br.last {
                    display: none;
                }
            }

            @media screen and (min-width: @tabletWidth) {
                br {
                    display: none;
                }
            }
        }
    }

    #footerCopyright {
        margin: 0 0 0.8rem;
    }

    #footerLinks {
        line-height: 2rem;

        ul {
            cursor: default;
            white-space: normal;

            @media screen and (min-width: @tabletWidth) {
                white-space: normal;

                br {
                    display: none;
                }
            }

            @media screen and (max-width: @smallPhoneWidth) {
                white-space: normal;
            }
        }

        li {
            position: relative;
            margin-right: 1.5rem;

            &:nth-child(2) {
                &:after {
                    display: none;
                }
            }

            @media screen and (min-width: @tabletWidth) {
                &:nth-child(2) {
                    &:after {
                        display: inline-block;
                    }
                }
            }



            &:after {
                content: '';
                display: inline-block;
                width: 1px;
                height: 12px;
                right: -20px;
                position: relative;
                border-right: 1px solid @white;
            }

            &:last-child {
                margin-right: 0;
                padding-right: 0;

                &:after {
                    display: none;
                }
            }
        }
    }
}
