﻿@import "colours.less";
@import "sizes.less";

.pageLayout() {
    max-width: @maxPageWidth;
    margin: 0 auto;

    &.padded {
        .pad();
    }
}

.pad() {
    max-width: (@maxPageWidth);
    padding-left: 15px;
    padding-right: 15px;

    @media screen and (min-width:@tabletWidth) and (max-width:(@desktopWidth - 1)) {
        padding-left:35px;
        padding-right:35px;
    }
}

/*used to set up background images*/
.cover() {
    display: block;
    background: transparent url() no-repeat right center;
    background-size: cover;

    @media screen and (min-width:@tabletWidth) and (max-width:(@desktopWidth - 1)) {
        background: transparent url() no-repeat center center;
    }
}

/*used to provide a single-colour overlay to content block background images*/
.overlay(@bgColour) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: @bgColour;
}

/*used where white text is shown in a block over a background image*/
.textWithBackground() {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    color: @white;

    a {
        color: @white;
    }
}

/*bordered header text*/
.bordered() {
    /*Works in Chrome, Firefox*/
    max-width: 560px;
    line-height: 1.15;
    color: @white;
    text-transform: uppercase;

    > span {
        outline: 3px solid @white;

        > span {
            border: 1px solid @black;
            background-color: @black;
            position: relative;
            z-index: 1;
            padding: 0.16rem 0.6rem;
            box-decoration-break: clone;
            -webkit-box-decoration-break: clone;
        }
    }
    //
    //iOS
    @supports

    (-webkit-overflow-scrolling: touch) {
        /* Works in Safari/iOS, Chrome */
        padding: 2px 0;

        > span {
            outline: 3px solid @white;
            background-color: @black;
            display: inline;
            padding: 0.1rem 0.6rem;
            box-decoration-break: clone;
            -webkit-box-decoration-break: clone;

            > span {
                border: none;
                background-color: transparent;
                position: static;
                padding: 0;
            }
        }
    }
    //
    //OS X Safari
    @media screen and (-webkit-max-device-pixel-ratio:1) and (min-color-index:0) {
        @supports

        (-webkit-appearance:none) and (stroke-color:transparent) {
            padding: 2px 0;

            > span {
                outline: 3px solid @white;
                background-color: @black;
                display: inline;
                padding: 0.1rem 0.6rem;
                box-decoration-break: clone;
                -webkit-box-decoration-break: clone;

                > span {
                    border: none;
                    background-color: transparent;
                    position: static;
                    padding: 0;
                }
            }
        }
    }
    //
    //Edge
    @supports

    (-ms-ime-align:auto) {
        display: block;
        margin-left: 14px;

        > span {
            position: relative;
            line-height: 1;
            outline: 3px solid @white;
            display: inline;
            white-space: pre-wrap;
            border: 0 solid @black;
            border-width: 0.1em 0;
            position: relative;
            z-index: 1;
            padding-right: 10px;
            background: @black;

            > span {
                border: none;
                padding: 0;
            }

            &:before {
                content: "";
                position: absolute;
                right: 100%;
                top: -0.15em;
                bottom: -0.15em;
                width: 0.25em;
                background: @black;
                border: 3px solid @white;
                border-right: none;
            }
        }
    }
    //IE10+
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        display: block;
        margin-left: 14px;

        > span {
            position: relative;
            line-height: 1;
            outline: 3px solid @white;
            display: inline;
            white-space: pre-wrap;
            border: 0 solid @black;
            border-width: 0.1em 0;
            position: relative;
            z-index: 1;
            padding-right: 10px;
            background: @black;

            > span {
                border: none;
                padding: 0;
            }

            &:before {
                content: "";
                position: absolute;
                right: 100%;
                top: -0.15em;
                bottom: -0.15em;
                width: 0.25em;
                background: @black;
                border: 3px solid @white;
                border-right: none;
            }
        }
    }
}
/*version of the bordered styling with black text and border, and white background.*/
.blackBordered() {
    color: @black;
    margin: -0.6em 0 1em;
    position: relative;
    left: 2px;
    max-width: 450px;

    > span {
        outline: 2px solid @black;

        > span {
            border: 1px solid @white;
            background-color: @white;
            padding: 0.2rem 0.6rem;
        }
    }
    //
    //iOS
    @supports

    (-webkit-overflow-scrolling: touch) {
        /* Works in Safari/iOS, Chrome */
        padding: 2px 0;

        > span {
            outline: 2px solid @black;
            background-color: @white;
            padding: 0rem 0.6rem;

            > span {
                border: none;
                background-color: transparent;
                padding: 0;
            }
        }
    }

    @media screen and (min-width: 415px) and (max-width:(@desktopWidth - 1)) {
        padding: 2px 0;

        > span {
            outline: 2px solid @black;
            background-color: @white;

            > span {
                border: none;
                background-color: transparent;
                padding:  0rem 0.6rem;
            }
        }
    }

    //
    //OS X Safari
    @media screen and (-webkit-max-device-pixel-ratio:1) and (min-color-index:0) {
        @supports

        (-webkit-appearance:none) and (stroke-color:transparent) {
            padding: 2px 0;

            > span {
                outline: 2px solid @black;
                background-color: @white;
                padding: 0rem 0.6rem;

                > span {
                    border: none;
                    background-color: transparent;
                    padding: 0;
                    color: @black;
                }
            }
        }
    }
    //
    //Edge
    @supports

    (-ms-ime-align:auto) {
        margin-left: 13px;
        left: 0;

        > span {
            outline: 2px solid @black;
            border: 0 solid @white;
            border-width: 0.1em 0;
            background: @white;

            > span {
                border: none;
                padding: 0;
            }

            &:before {
                background: @white;
                border: 2px solid @black;
                border-right: none;
                top: -0.15em;
                bottom: -0.15em;
                z-index: 10;
            }
        }
    }
    //IE10+
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        margin-left: 13px;
        left: 0;

        > span {
            outline: 2px solid @black;
            border: 0 solid @white;
            border-width: 0.1em 0;
            background: @white;

            > span {
                border: none;
                padding: 0;
            }

            &:before {
                background: @white;
                border: 2px solid @black;
                border-right: none;
                top: -0.15em;
                bottom: -0.15em;
                z-index: 10;
            }
        }
    }
}


/*linkbox styling (used for onward journey links etc.)*/
.linkBox() {
    box-sizing: border-box;
    flex: 1 1 30%;
    position: relative;
    min-width: 300px;

    @media screen and (max-width:@smallPhoneWidth) {
        min-width: 290px;
    }

    margin: 0 7px 22px 7px;

    > a:first-of-type, > span {
        text-decoration: none;
        display: block;
        padding: 25px 25px 15px;
    }

    h3 {
        font-size: 1.27rem;
        text-decoration: none;
        display: inline-block;
        padding-right: 30px;

        > .lastWord {
            display: inline-block;

            @media screen and (max-width:(@desktopWidth - 1)) {
                background: transparent url(../images/arrow-right.png) no-repeat right top 5px;
                background-size: 18px auto;
                padding-right: 28px;
            }
        }
    }

    h4 {
        font-size: 1.1rem;
    }

    .text {
        position: relative;
        z-index: 10;
        max-width: 436px;
    }

    span.more {
        display: none;
        cursor: pointer;
        text-decoration: none;
    }

    @media screen and (min-width: @desktopWidth) {
        .showFullButtons();
    }
}

section.thumbLinks.tabletFull {
    @media screen and (min-width: @tabletWidth) {
        article {
            .showFullButtons();
        }
    }
}


.showFullButtons() {
    > a:first-of-type {
        padding: 25px 25px 70px;
        cursor: default;
    }

    h3 {
        background: none;
        padding-right: 0;

        > .lastWord {
            background: none;
            padding-right: 0;
        }
    }

    .arrowButton();
}

.enlargeOnHover() {
    transition: transform @transitionSpeed;
    transition-delay: @transitionDelay;

    &:not(.selected) {
        &:hover, &:focus {
            transform: @buttonHoverTransform;
        }
    }
}

/*variation on the link box, with button overlaying bottom edge, and different colouring (used for additional advice links etc.)*/
.simpleLinkBox() {
    .linkBox();
    border: 2px solid @simpleLinkBoxBorder;
    flex: 1 1 100%;
    min-width: 240px;

    > a:first-of-type {
        padding: 15px 20px 5px;
    }

    h3 {
        > .lastWord {
            display: inline-block;
            background: transparent url(../images/arrow-right-black.png) no-repeat right top 5px;
            background-size: 18px auto;
            padding-right: 28px;
        }
    }

    @media screen and (min-width: @tabletWidth) {
        flex-basis: 40%;
    }

    @media screen and (min-width: @desktopWidth) {
        flex-basis: 20%;

        > a:first-of-type {
            padding: 20px 25px 36px;
        }

        h3 {
            background: none;

            > .lastWord {
                background: none;
            }
        }

        .arrowButtonOnEdge(@highlightedSectionBackgroundColour);
        margin-bottom: 2rem;
    }
}

/*variation on the simple link box, with rectangular thumbnail image at top (used for "How you can help" etc.)*/
.simpleImageLinkBox() {
    .linkBox();
    .arrowButton();
    .arrowButtonOnEdge(@white);
    border: 2px solid @simpleLinkBoxBorder;
    flex: 1 1 100%;
    min-width: 200px;
    .rectangleThumb(181px);
    margin-top: 32px;
    padding-bottom: 20px;

    > a:first-of-type {
        padding: 15px 20px 5px;
    }

    span.more {
        background-color: @white;
    }


    @media screen and (min-width: @tabletWidth) {
        flex-basis: 40%;
        margin-top: 49px;
    }

    @media screen and (min-width: @desktopWidth) {
        flex-basis: 20%;
        margin-left: 7px;
        margin-right: 7px;

        > a:first-of-type {
            padding: 20px 25px 5px;
        }

        h3 {
            background: none;
        }

        .arrowButtonOnEdge(@white);
    }
}

.newsArticleBlock() {
    @media screen and (max-width: (@tabletWidth - 1)) {
        .linkBox();
        .rectangleThumb(157px);
    }

    flex: 1 1 100%;
    margin: 32px 7px 0;
    background-color: @white;
    box-shadow: @boxShadowStandard;

    .image {
        display: none;
    }

    h3 .lastWord {
        display: inline-block;
        background: transparent url(../images/arrow-right-black.png) no-repeat right top 6px;
        background-size: 18px auto;
        padding-right: 28px;
    }

    @media screen and (min-width: @tabletWidth) {
        .imageThumbnail {
            display: none;
        }

        .image {
            display: block;
        }

        .newsArticleWithThumbnail();
    }

    @media screen and (min-width: @desktopWidth) {
        flex-basis: 45%;
    }
}

.newsArticleWithThumbnail() {
    overflow: hidden;
    box-shadow: @boxShadowStandard;
    background-color: @white;
    margin-bottom: 20px;

    .text {
        padding: 10px 172px 7px 15px;

        h4, h3 {
            display: inline;
            font-size: 1.15rem;
            padding-right: 30px;

            .lastWord {
                display: inline-block;
                background: transparent url(../images/arrow-right-black.png) no-repeat right top 5px;
                background-size: 18px auto;
                padding-right: 28px;
            }
        }

        .datestamp {
            display: block;
            margin-top: 0.5rem;
            font-size: 0.8rem;
            color: @articleDateColour;
        }

        @media screen and (min-width: @tabletWidth) {
            padding: 20px 172px 15px 25px;
        }
    }

    .image {
        float: right;

        img {
            display: block;
        }
    }
}

/*variation on the simple link box, with button overlaying bottom edge, and different colouring (used for additional advice links etc.)*/
.caseStudyBox() {
    .simpleLinkBox();
    .arrowButton();
    .arrowButtonOnEdge(@white);
    text-align: center;
    margin-top: 140px;
    margin-bottom: 35px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 40px;
    padding-right: 40px;
    max-width: 520px;
    .circleThumb();

    h3 {
        background: none;
        padding-right: 0;
        margin-bottom: 0.3rem;
    }

    h4 {
        font-weight: normal;
        font-size: 1.1rem;
        margin-bottom: 1rem;
    }

    p:last-of-type {
        margin-bottom: 10px;
    }

    span.more {
        display: inline-block;
        background-color: @white;
        margin: 0 auto;
        position: relative;
        margin-top: -10px;
        margin-bottom: -10px;
    }


    @media screen and (min-width: @tabletWidth) {
        h3 {
            margin-top: 15px;
        }

        p:last-of-type {
            margin-bottom: 15px;
        }
    }

    @media screen and (min-width: @desktopWidth) {
        flex: 1 1 20%;
        margin-top: 110px;
        margin-left: 7px;
        margin-right: 7px;

        p:last-of-type {
            margin-bottom: -3px;
        }

        div.btn {
            position: absolute;
            left: 0;
            bottom: -18px;
            text-align: center;
            width: 100%;

            span.more {
                top: 0;
                display: inline-block;
            }
        }
    }
}



/*button with arrow, expands to text plus arrow*/
.arrowButton() {
    span.more {
        display: inline-block;
        text-decoration: none;
        font-weight: bold;
        outline: none;
        position: absolute;
        bottom: 20px;
        color: @white;
        box-sizing: border-box;
        overflow: hidden;
        border: 2px solid @white;
        border-radius: 50px;
        background: transparent url(../images/arrow-right.png) no-repeat right 10px center;
        background-size: 20px auto;
        height: 44px;
        width: auto;
        max-width: 44px;
        min-width: 44px;
        transition: max-width @transitionSpeed, background-color @transitionSpeed, border-color @transitionSpeed, transform @transitionSpeed, background-image @transitionSpeed;
        transition-delay: @transitionDelay;

        &:hover, &:focus {
            transition-delay: 0;
            transform: scale(1.05, 1.05);
        }

        span {
            opacity: 0;
            transition: opacity @transitionSpeed;
            transition-delay: @transitionDelay;
            float: right;
            padding: 10px 45px 0 20px;
            white-space: nowrap;
            text-align: right;
        }
    }

    &:hover, &:focus, &.alwaysVisible {
        span.more {
            span {
                opacity: 1;
            }

            background-color: @red;
            border-color: @red;
            max-width: 350px;
        }
    }
}

/*button with arrow, expands to text plus arrow, sits over the bottom edge of the content box*/
.arrowButtonOnEdge(@arrorButtonOffColour) {
    span.more {
        border-color: @simpleLinkBoxBorder;
        background-color: @arrorButtonOffColour;
        background-image: url(../images/arrow-right-blue.png);
        bottom: -23px;
    }

    &:hover, &:focus {
        span.more {
            background-color: @simpleLinkBoxBorder !important;
            border-color: @simpleLinkBoxBorder;
            background-image: url(../images/arrow-right.png);
        }
    }
}


/*Thumbnail sizing and positioning*/
/*Circular thumbnail used for case studies*/
.circleThumb() {
    .imageThumbnail, .videoThumbnail {
        margin: -120px auto 20px;
        width: 208px;
        height: 208px;
        border-radius: 104px;
        box-sizing: border-box;
    }

    .videoThumbnail {
        display: inline-block;
        margin: -110px auto 10px;
        top: 7px;

        &:after {
            left: 75px;
            top: 167px;
        }
    }
}

/*Rectangular thumbnail used for article lists*/
.rectangleThumb(@imageHeight) {
    padding-top: @imageHeight;

    &.noThumbs {
        padding-top: 0;
    }

    .imageThumbnail, .videoThumbnail {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: @imageHeight;
    }

    .videoThumbnail:after {
        top: 63px;
        background-color: transparent;
    }

    @media screen and (min-width: @tabletWidth) {
        padding-top: 207px;
        max-width: 584px;
        .centreUsingMargins();

        .imageThumbnail, .videoThumbnail {
            height: 207px;
        }

        .videoThumbnail:after {
            top: 77px;
            left: 46%;
        }
    }

    @media screen and (min-width: @desktopWidth) {
        padding-top: 187px;

        .imageThumbnail, .videoThumbnail {
            height: 187px;
        }

        .videoThumbnail:after {
            top: 66px;
            left: 40%;
        }
    }
}

.videoIndicator(@buttonFillColour) {
    &:after {
        display: block;
        position: absolute;
        top: 40%;
        left: 43%;
        content: '';
        color: @white;
        background: @buttonFillColour url(../images/btn-play.svg) no-repeat 16px center;
        width: 60px;
        height: 60px;
        border: 6px solid @white;
        border-radius: 50%;
        text-decoration: none;
        font-size: 1.9em;
        font-weight: bold;
        text-align: center;
        box-sizing: border-box;
        padding: 5px 0 0 5px;
        box-shadow: @black 0 0 10px;
    }
}

.donateButton() {
    background-color: @donateButtonBackground;
    color: @donateButtonText;
    padding: 12px 15px;
    box-sizing: border-box;
    text-decoration: none;
    border-radius: 0;

    @media screen and (min-width: @tabletWidth) {
        /*header donate button only gets bigger on desktop viewport*/
        &:not(#headerDonate) {
            padding: 18px 40px;
        }
    }

    @media screen and (min-width: @desktopWidth) {
        padding: 18px 40px;
    }
}

.surroundWithQuotes() {
    &:before {
        content: '\201C'
    }

    &:after {
        content: '\201D'
    }
}

.centreUsingMargins() {
    margin-left: auto;
    margin-right: auto;
}
