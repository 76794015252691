﻿@import "colours.less";
@import "sizes.less";
@import "mixins.less";

header {
    #headerSkipToMain {
        overflow: hidden;
        text-indent: -1000px;
        position: absolute;
        display: inline;
    }

    .headerPage {
        position: relative;
        margin: 0 auto;
        max-width: @maxPageWidth;
        padding: 10px 15px 10px;

        @media screen and (min-width:@tabletWidth) and (max-width:(@desktopWidth - 1)) {
            padding-left: 35px;
            padding-right: 35px;
        }
    }

    #siteLogo img {
        width: 170px;
        height: auto;
        float: left;
        margin-right: 15px;
        margin-top: 7px;

        @media screen and (max-width:@smallPhoneWidth) {
            width: 130px;
            margin-top: 11px;
        }
    }

    #headerCaption {
        display: none;
        float: left;
        max-width: 290px;
    }

    #rightButtons {
        float: right;
    }

    #headerDonate {
        display: inline-block;
        vertical-align: top;
        .donateButton();
    }


    .headerTelephone {
        color: @white;
        background: @lightBlue url(../images/phone-wt.svg) no-repeat 20px center;
        background-size: 22px 39px;
        padding: 5px 7px 7px 60px;
        box-sizing: border-box;

        

        a {
            color: @white;

            &[href^=tel] {
                font-weight: bold;
                font-size: 1.2em;
            }
        }

        &.desk {
            display: none;
            margin-right: 15px;
            width: 290px;
            height: 56px;
            float: left;
        }

        &.light {
            color: #022664;
            background: #EDEDED url(../images/phone-blue.png) no-repeat 20px center;
            width: 430px;

            @media screen  and (max-width:(@desktopWidth - 1)) {
                width: 100%;
            }

            a {
                color: #022664;
            }
        }

        

        &.mob {
            @media screen and (min-width:@tabletWidth) and (max-width:(@desktopWidth - 1)) {
                padding-left: 75px;
                background-position:36px;

                .header-btn-chat {
                    right: 10px;
                    position: absolute;
                    height: 44px;
                }
            }
            display: flex;
        }
    }

    #headerFollow, #headerContactUs, #headerSearch {
        display: none;
    }

    @media screen and (min-width: @headerCaptionShown) {
        #headerCaption {
            display: block;
        }
    }

    @media screen and (min-width: @desktopWidth) {

        .headerPage {
            padding: 28px 0 18px;
        }

        #siteLogo img {
            width: 280px;
            margin-top: -9px;
        }


        .headerTelephone {
            &.desk {
                display: flex;
            }

            &.mob {
                display: none;
            }
        }

        #mainNavBurger {
            display: none;
        }

        #headerDonate {
            height: 56px;
        }

        #rightButtons {
            margin-top: -8px;
        }
    }
}
