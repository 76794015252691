﻿@import "colours.less";
@import "sizes.less";
@import "reset.less";
@import "base.less";
@import "main-nav.less";
@import "mixins.less";
@import "content-blocks.less";
@import "header.less";
@import "footer.less";
@import "forms.less";
@import "components.less";
@import "payment-buttons.less";

.page {
    .pageLayout();
}

.greybg {
    background-color: @highlightedSectionBackgroundColour;
}

body {
    background-color: @bodyBackground;

    *:before, *:after {
        -webkit-box-sizing: initial;
        -moz-box-sizing: initial;
        box-sizing:  initial;
    }

    .news-article-published-date {
        display: inherit;
        padding: .7em 0 .7em;
        font-style: italic;
        font-size: .9em;
    }

    .upshifted {
        /*margin-top: 60px;*/ //commented out as it was affecting /volunteer
        section.newsletterSignUp {
            article {
                margin-top: -95px;
            }
        }
    }

    section {

        .highlight {
            background-color: @highlightedSectionBackgroundColour;
        }

        > section {
            &.titled {
                background-color: @white;
                border-top: 2px solid @black;
                margin-top: 4.5rem;
                padding-bottom: 1.4rem;

                > h1, article > h1 {
                    .bordered();
                    .blackBordered();
                    font-size: @fontSizeMassive;

                    @media screen and (min-width: @tabletWidth) {
                        font-size: @fontSizeEnormous;
                        max-width: 660px;
                    }
                }

                article h2, > h2, div:not(.inner):not(.RTE) > h2 {
                    .bordered();
                    .blackBordered();
                }
            }


            @media screen and (min-width: @desktopWidth) {
                .articleWithSidebar {
                    display: flex;
                    align-items: flex-start;

                    > article {
                        flex: 1 1 90%;
                        margin-bottom: 40px;
                    }

                    aside {
                        flex: 1 1 10%;
                        min-width: 352px;
                        margin-left: 30px;

                        &.small {
                            min-width: 260px;
                        }
                    }

                    &.upshifted {
                        aside {
                            position: relative;
                            top: -45px;

                            &.categories {
                                top: -85px;
                            }
                        }

                        &.lessUpshift {
                            aside {
                                top: -32px;
                            }
                        }
                    }
                }
            }

            aside {
                > div.inner {
                    border: @asideBorder;
                    box-sizing: border-box;
                    margin-bottom: 20px;
                    background-color: @white;
                    position: relative;

                    .pad {
                        padding: 10px 15px;

                        a {
                            color: @lightBlue;
                        }

                        blockquote:first-child {
                            margin-top: 0;
                            padding-top: 0;
                        }
                    }

                    .sidebarTopImage + .pad {
                        margin-top: 1rem;
                    }

                    a.image.video + .pad {
                        margin-top: 0.5rem;
                    }

                    .image {
                        display: block;
                        position: relative;
                    }

                    .video {
                        .videoIndicator(@videoIndicatorFillColour);

                        &:after {
                            left: 41%;
                            top: 34%;
                        }
                    }
                }

                img.sidebarTopImage {
                    display: block;
                    width: 100%;
                }

                &.withCta {
                    .pad {
                        margin-bottom: 10px;
                    }
                }

                &.withImage {
                    clear: both;

                    > div.inner {
                        //padding-top: 235px;
                    }
                }

                .btn {
                    .arrowButton();
                    .arrowButtonOnEdge(@white);
                    margin-left: 20px;
                }

                h2, h3, h4 {
                    font-weight: bold;
                }

                h2 {
                    font-size: @fontSizeBig;
                }

                h3 {
                    font-size: 1.1rem;
                }

                h4 {
                    font-size: @fontSizeNormal;
                }

                blockquote {
                    font-size: 1.3rem;
                    text-align: center;
                    margin: .5rem 2rem;
                    .surroundWithQuotes();
                }

                @media screen and (min-width: @tabletWidth) {
                    margin-left: auto;
                    margin-right: auto;
                    margin-bottom: 50px;
                    max-width: 520px;
                }
            }
        }

        .breadcrumb + section.titled {
            margin-top: 30px;
        }
    }

    section.highlight + section.titled {
        margin-top: 0;
    }

    p.intro {
        font-size: 1.1rem;
    }

    .overlap {
        margin-top: 90px;

        .imageOrVideo {
            margin-top: -60px;
        }
    }

    .RTE {
        iframe {
            width: 100%;
        }

        p {
            margin-bottom: 1.6em;

            .introText {
                font-size: 1.25rem;
            }
        }
    }

    section.lastSection {
        margin-bottom: 30px;
    }

    .squared {
        border-radius: 0;
    }
}

@import "media-queries.less";
