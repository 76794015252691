﻿@import "colours.less";
@import "sizes.less";

@media screen and (min-width: 400px) {
}

@media screen and (min-width: 500px) {
}

@media screen and (min-width: 640px) {
}

@media screen and (min-width: 760px) {
}

@media screen and (min-width:760px) and (max-width:@desktopWidth) {
}

//DESKTOP
@media screen and (min-width: @desktopWidth) {
    body {

        section > section {
            &.withContact {
                padding-bottom: 100px;
            }
        }
    }
}


@media screen and (min-width: 1320px) {
}

@media screen and (min-width: 1440px) {
}
