﻿@import "colours.less";
@import "sizes.less";

@keyframes bounce {
    0% {
        transform: translateY(0px)
    }

    50% {
        transform: translateY(5px)
    }

    100% {
        transform: translateY(0px)
    }
}

@-webkit-keyframes bounce {
    0% {
        transform: translateY(0px)
    }

    50% {
        transform: translateY(5px)
    }

    100% {
        transform: translateY(0px)
    }
}

#mainNavBurger {
    font-size: 3em;
    width: 40px;
    cursor: pointer;
    color: transparent;
    display: inline-block;
    vertical-align: top;
    height: 50px;
    overflow: hidden;
    background: transparent url(../images/burger.svg) no-repeat right center;

    &.open {
        background: transparent url(../images/nav-close.svg) no-repeat right 3px center;
    }
}

#mainNav {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    position: absolute;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.15) 0 3px 6px;
    z-index: 30;

    > div > ul {
        display: none;
        width: 100%;
        background-color: @mainNavParentBackgroundColour;

        > li {
            position: relative;
            font-size: 1.1rem;
            font-weight: bold;

            &.selected {
                background-color: @mainNavChildBackgroundColour;
                border-top: 5px solid @mainNavChildTextColour;

                a {
                    color: @mainNavChildTextColour;
                }

                .expander {
                    background-image: url(../images/arrow-up-blu.svg);
                }
            }

            > a {
                color: @mainNavTextColour;
                display: block;
                text-decoration: none;
                padding: 20px 20px;
            }

            section {
                background-color: @mainNavChildBackgroundColour;
                font-weight: normal;
                font-size: 1rem;
                max-width: 920px;

                a {
                    color: @mainNavLinkColour;
                }

                button, a[role=button] {
                    background-color: @lightBlue;
                    color: @white;
                }

                a[role=button] {
                    padding-top: 12px;
                    padding-bottom: 14px;
                }

                article {
                    p.contactlink {
                        margin-top: 2rem;
                    }
                }

                ul {
                    break-inside: avoid-column;
                }
            }

            .expander {
                cursor: pointer;
                position: absolute;
                right: 0;
                top: 0;
                width: 60px;
                height: 60px;
                box-sizing: border-box;
                padding: 10px;
                background: transparent url(../images/arrow-down.svg) no-repeat center center;
                background-size: 21px auto;
            }

            > section {
                display: none;
                width: 100%;
                background-color: @mainNavChildBackgroundColour;

                > div.menu-items {
                    padding-right: 10px;
                    text-align: left;

                    > ul[title]::before {
                        content: attr(title);
                        /* then add some nice styling as needed, eg: */
                        display: block;
                        text-transform: uppercase;
                        font-weight: bold;
                        padding: 0px;
                        color: #70777E;
                        margin: 0 0 20px 25px;
                    }

                    > ul {
                        text-align: left;

                        > li {
                            font-size: 1.1rem;
                            font-weight: bold;

                            a {
                                color: @mainNavChildTextColour;
                                text-decoration: none;
                                display: block;
                                padding: 0px 10px 20px 25px;
                            }
                        }
                    }
                }
            }
        }
    }

    #headerSearchLink {
        display: none;
        padding-left: 42px;
        background: transparent url(../images/search-mag.svg) no-repeat 10px 10px;
        background-size: 24px 24px;
    }

    #btnSearch {
        display: none;
    }

    #headerSearch {
        display: block;
        text-align: center;
        padding-left: 15px;
        padding-right: 15px;

        #frmSiteSearch {
            padding: 10px 0;

            div {
                margin-right: 25px !important;
                margin-top: 0;
                padding-left: 38px;
                background: transparent url(../images/search-mag-solid-blu.svg) no-repeat left 0 bottom 13px;
                background-size: 24px 24px;
                width: 100%;
                max-width: 400px;
                text-align: left;
                position: relative;

                input[type=search] {
                    border-color: @deepBlue;
                    width: 100%;
                    box-sizing: border-box;
                }

                .field-validation-error {
                    position: absolute;
                    bottom: -8px;
                }
            }
        }
    }

    #headerContactUs {
        article {
            margin: 0 25px 10px;
            background-color: @beige;
            padding: 30px 30px 40px;

            p {
                line-height: 1rem;
                margin-bottom: 1rem;

                &.contactlink {
                    margin-bottom: 0;
                }
            }

            &:last-of-type {
                //margin-bottom: 0;
            }

            h3 {
                padding: 4px 0 4px;
                margin-bottom: 0.7rem;

                &.contact {
                    padding-left: 52px;
                    background: transparent url(../images/ico-speech-square.svg) no-repeat left center;
                    background-size: 43px 35px;
                }

                &.find {
                    padding-left: 39px;
                    background: transparent url(../images/location-arrow.svg) no-repeat left center;
                    background-size: 28px auto;
                }
            }

            &#headerContactBranch {
                h3 {
                    margin-bottom: 1rem;
                    font-size: 1.25rem;
                }

                .btns {
                    margin-top: 1.7rem;
                }
            }

            &#headerFindVolunteerRoles {
                h3 {
                    margin-bottom: 1rem;
                    font-size: 1.25rem;

                    &.find {
                        padding-left: 30px;
                        background: transparent url(../images/location-arrow.svg) no-repeat left center;
                        background-size: 24px 23px;
                    }
                }

                .btns {
                    margin-top: 1.7rem;
                }
            }

            &#headerContactTelephone, &#headerSAFFAShop, &#headerSAFFACareers {
                p {
                    margin-top: 10px;
                }

                .bold {
                    font-weight: bold;
                }

                .cta {
                    padding-top: 4px;
                    padding-right: 0px;
                    padding-bottom: 4px;
                    padding-left: 52px;
                    background: transparent url(../images/phone-bk.svg) no-repeat left center;
                    background-size: 43px 35px;
                }

                .cts {
                    padding-top: 4px;
                    padding-right: 0px;
                    padding-bottom: 4px;
                    padding-left: 30px;
                    background: transparent url(../images/phone-bk.svg) no-repeat left center;
                    background-size: 24px 23px;
                }

                .cas {
                    padding-top: 4px;
                    padding-right: 0px;
                    padding-bottom: 4px;
                    padding-left: 30px;
                    background: transparent url(../images/ico-ssafa-careers.svg) no-repeat left center;
                    background-size: 24px 23px;
                }

                .sas {
                    padding-top: 4px;
                    padding-right: 0px;
                    padding-bottom: 4px;
                    padding-left: 40px;
                    background: transparent url(../images/ico-careworker.png) no-repeat left center;
                    background-size: 35px 33px;
                }

                .telephone {
                    margin-top: 1rem;
                    margin-bottom: 0;
                    padding-top: 1em;
                    text-align: center;

                    a {
                        display: inline-block;
                        background-color: @lightBlue;
                        padding: 12px 15px;
                        color: @white;
                        font-size: @fontSizeBigger;
                        font-weight: 900;
                    }
                }

                a.arrow {
                    padding-right: 40px;
                }
            }

            &#headerContact {
                a {
                    font-weight: bold;
                }
            }
        }
    }

    #headerFollow {
        text-align: center;

        div {
            background-color: @white;

            li {
                display: inline-block;
                color: transparent;
                margin-left: 7px;
                margin-right: 7px;

                a {
                    display: block;
                    width: 90px;
                    height: 90px;
                    background-position: center center;
                    background-repeat: no-repeat;
                    overflow: hidden;
                    color: transparent;
                    text-indent: -1000px;

                    &:hover, &:active {
                        opacity: 0.8;
                    }
                }


                &.facebook a {
                    background-image: url(../images/facebook-blu.svg);
                    background-size: @headerfacebookW @headerfacebookH;
                    width: @headerfacebookW;
                }

                &.linkedin a {
                    background-image: url(../images/linkedin-blu.svg);
                    background-size: @headerlinkedinW @headerlinkedinH;
                    width: @headerlinkedinW;
                }

                &.twitter a {
                    background-image: url(../images/twitter-blu.svg);
                    background-size: @headertwitterW @headertwitterH;
                    width: @headertwitterW;
                }

                &.youtube a {
                    background-image: url(../images/youtube-blu.svg);
                    background-size: @headeryoutubeW @headeryoutubeH;
                    width: @headeryoutubeW;
                }

                &.instagram a {
                    background-image: url(../images/instagram-blu.svg);
                    background-size: @headerinstagramW @headerinstagramH;
                    width: @headerinstagramW;
                }

                &.tiktok a {
                    background-image: url(../images/tik-tok-navy.png);
                    background-size: @headerinstagramW @headertiktokH;
                    width: @headertiktokW;
                }
            }
        }
    }
}

@media screen and (min-width: @mainNavContacUsHorizontal) {
    #mainNav {
        #headerSearch {
            text-align: left;
            padding-left: 20px;
        }
    }
}

@media screen and (min-width: @mainNavContacUsHorizontal) {
    #mainNav {
        > div > ul:first-of-type {
            //page links
        }

        section {
            padding-right: 40px;

            &#headerFollow {
            }

            &#headerContactUs {
                padding-left: 15px;
                padding-right: 15px;

                .page {
                    display: flex;
                }

                article {
                    flex: 1 1 30%;
                    display: inline-block;
                    margin: 30px 14px 30px 0;

                    &:last-of-type {
                        margin-bottom: 30px;
                        margin-right: 0;
                    }

                    &:only-of-type{
                        margin-bottom: 30px;
                        margin-right: 0;
                        margin-top: 0;
                    }
                }
            }

            &#headerSearch {
            }
        }
    }
}

@media screen and (min-width: @mediumTabletWidth){
    #mainNav {
        > div > ul {
            > li {
                //Submenu items
                > section {
                    display: none;

                    > div.menu-items {
                        display: inline-block;
                        width: 50%;
                        column-count: 1;
                        transition-property: opacity;
                        transition-duration: 0s;
                        transition-delay: 0s;

                        > ul[title]::before {
                            content: attr(title);
                            /* then add some nice styling as needed, eg: */
                            display: block;
                            font-weight: bold;
                            padding: 0px;
                            color: #70777E;
                        }
                    }

                    > article {
                        width: 50%;
                        float: right;
                    }
                }
            }
        }
    }
}
@media (min-width: @desktopWidth) {
    #mainNav {
        background-color: @mainNavWideParentBackgroundColour;
        position: relative;

        #mainNavBurger {
            display: none;
        }


        > div > ul {
            background-color: transparent;
            display: inline-block !important;
            width: auto;
            //Menu item
            > li {
                display: inline-block !important;
                position: relative;

                > a {
                    color: @mainNavWideTextColour;
                    display: block;
                    text-decoration: none;
                    padding: 11px 25px 16px;
                    border-top: 5px solid transparent;
                    min-height: 54px;
                    box-sizing: border-box;
                    transition-property: background-color, border-color, color, background-image;
                    transition-duration: 0s;
                    transition-delay: 0s;
                }

                &:hover, &:active, &:focus {
                    > a {
                        background-color: @white;
                        color: @deepBlue;
                        border-top: 5px solid @deepBlue;
                        transition-property: background-color, border-color, color, background-image;
                        transition-duration: @transitionSpeed;
                        transition-delay: 0s;
                    }

                    > section {
                        transition-property: opacity;
                        transition-duration: @transitionSpeed;
                        transition-delay: 0s;
                        display: block !important;
                        opacity: 1;
                        padding: 1rem 0;
                        height: auto;
                        border-width: 1px;
                        z-index: 1000;
                    }

                    #headerSearchLink {
                        background-color: @white;
                        background-image: url(../images/search-mag-blu.svg);
                    }
                }

                .expander {
                    display: none;
                }
                //Submenu items
                > section {
                    position: absolute;
                    top: 54px;
                    display: block;
                    background-color: @mainNavWideChildBackgroundColour;
                    border: 0 solid @beige;
                    border-top: none;
                    width: 60vw;
                    padding: 0;
                    transition-property: opacity;
                    transition-duration: 0s;
                    transition-delay: 0s;
                    opacity: 0;
                    height: 0;
                    box-sizing: border-box;
                    overflow: hidden;
                    z-index: 900;

                    > div.menu-items {
                        display: inline-block;
                        width: 65%;
                        column-count: 2;
                        transition-property: opacity;
                        transition-duration: 0s;
                        transition-delay: 0s;

                        > ul[title]::before {
                            content: attr(title);
                            /* then add some nice styling as needed, eg: */
                            display: block;
                            font-weight: bold;
                            padding: 0px;
                            color: #70777E;
                            margin: 0 0 0px 10px;
                        }

                        > ul {
                            > li {
                                transition-property: background-color;
                                transition-duration: @transitionSpeed;
                                transition-delay: 0;
                                display: inline-block;
                                width: 100%;

                                &:hover {
                                    background-color: @menuSubnavHoverBackgroundColour;
                                }

                                a {
                                    padding: 14px 10px;
                                }
                            }

                            > li:last-child {
                                a {
                                    padding: 14px 10px 28px 10px;
                                }
                            }
                        }
                    }

                    > article {
                        width: 35%;
                        float: right;
                    }
                }
            }

            &#auxNavLinks {
                float: right;

                li {
                    outline: none;

                    section {
                        display: block !important;
                        width: 100%;
                        max-width: unset;
                        height: 0;
                        opacity: 0;
                        transition-property: opacity;
                        transition-duration: 0s;
                        transition-delay: 0s;
                        overflow: hidden;
                        box-sizing: border-box;
                        padding: 0;
                        z-index: 900;
                    }

                    &:hover, &:active, &:focus {
                        position: static;

                        .expander {
                            display: none;
                        }

                        section {
                            display: block !important;
                            height: auto;
                            opacity: 1;
                            transition-property: opacity;
                            transition-duration: @transitionSpeed;
                            transition-delay: 0s;
                            z-index: 1000;
                        }
                    }
                }
            }
        }

        section {
            position: absolute;
            width: 100%;
            left: 0;
            box-shadow: rgba(0, 0, 0, 0.15) 0 5px 5px;

            &#headerFollow {
                display: none;
                background-color: @white;

                li {
                    margin: 0 1em;
                }
            }

            &#headerContactUs {
                display: none;
                background-color: @mainNavContactUsBackgroundColour;
            }

            &#headerSearch {
                display: none;
                background-color: @white;
                text-align: center;
                padding: 20px 0;
            }
        }

        #headerSearchLink {
            display: block;
        }

        #frmSiteSearch {
            div {
                width: auto !important;

                input[type=search] {
                    width: 215px !important;
                }
            }
        }

        #btnSearch {
            display: inline;
            margin-top: 14px;
        }

        #headerSearch {
            display: none;
        }
    }

    body {
        #mainNav {
            ul.top-nav-item {
                width: 48%;

                a {
                    padding: 11px 15px 16px;
                }
            }
        }

        &.nav-pinned {
            #mainNav {
                position: fixed;
                top: 0;
                z-index: 200;
            }

            header div.headerPage {
                margin-bottom: 52px;
            }

            &.bannerBar-visible {
                #mainNav {
                    top: 40px;
                }
            }
        }
    }

    body.nav-pinned2 {
        padding-top: 65px;
        .scroll-nav {
            position: fixed !important;
            top: 54px;
            width: 100%;
            z-index: 50;
        }
    }
}
