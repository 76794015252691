﻿@import "colours.less";
@import "sizes.less";

body form {

    label, span, legend, label.checkbox {
        &.required, &.required.prefCentreCheckbox {
            &:after {
                content: '*';
                color: @requiredColour;
                margin-left: 6px;
                display: inline-block;
                font-size: @fontSizeBigger;
                position: relative;
                top: 3px;
                margin-right: -1em;
                opacity: 100;
            }
        }
    }

    label.checkbox.required.prefCentreCheckbox {
        &:after {
            top: -2px !important;
            margin-left: 0px;
        }
    }

    legend.required {
        &:after {
            top: -5px !important;
            margin-left: 0.25em;
            margin-right: 0em
        }
    }

    fieldset {
        //border-bottom: 2px solid @beige;
        //padding-bottom: 40px;
        margin-bottom: 40px;

        &:last-of-type {
            border: none;
            margin-bottom: 0;
            padding-bottom: 40px;
        }
    }

    .form-control {
        display: block;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        height: 2.8em;
        padding: .2em 0;
        background-color: #fff;
        border-bottom: 3px solid @black;
    }

    .form-group {
        margin-bottom: 2rem;

        > label {
            display: none;
        }
    }

    .requiredMessage {
        margin: 10px 0 0;
        padding: 0 0 10px;
        font-size: .95em;

        &::before {
            content: '* ';
            font-weight: bold;
            font-size: @fontSizeLarge;
            vertical-align: middle;
            color: @requiredColour;
        }
    }

    #card-number {
        position: relative;
        width: 100%;

        #ccAddon {
            position: absolute;
            right: 9px;
            top: 8px;
        }
    }

    #expiration-date {
        max-width: 215px;
    }

    .form-group.cvv {
        .row > div {
            display: inline-block;
        }

        a[data-toggle="popover"] {
            display: inline-block;
            width: 36px;
            height: 36px;
            background-color: @beige;
            border-radius: 50%;
            overflow: hidden;
            color: transparent;
            position: relative;
            margin-left: 2rem;
            outline: none;

            &:after {
                content: '?';
                color: @black;
                font-weight: 900;
                font-size: @fontSizeBig;
                position: absolute;
                left: 13px;
                top: 6px;
            }
        }
    }

    #cvv {
        max-width: 125px;
    }

    .checkboxInfo {
        margin-left: 45px;
    }
    //form field, in validation error state
    .input-validation-error {
        border-color: @requiredColour;
        background-image: url(../images/validation-cross.svg);
        background-size: 10px 10px;
        background-position: right 8px bottom 10px;
        background-repeat: no-repeat;
    }
    //validation message text
    .field-validation-error {
        color: @validationTextColour;
        font-size: @fontSizeTiny;
        display: block;
        margin-top: 5px;
    }

    .YesNoValidation {
        margin-top: 20px;
    }

    input, select, textarea {
        &.valid {
            border-color: @validationCorrectBorderColour;
            padding-right: 30px;
        }
    }

    input, textarea, select {
        &.valid {
            background-image: url(../images/validation-tick.svg);
            background-size: 16px auto;
            background-position: right 6px bottom 10px;
            background-repeat: no-repeat;
        }
    }
}

////////////////////////////////////////////////////
// 3D Secure iframe
#knockback {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: @knockbackColour;
    z-index: 1000;
}

#threeDSContainer {
    position: absolute;
    z-index: 1010;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    .inner {
        position: relative;
        margin: 0;
        width: 400px;
        height: 460px;
        background-color: #fff;
        box-shadow: rgba(0, 0, 0, 0.6) 0 0 16px;
        overflow: auto;

        @media screen and (max-width:(@tabletWidth - 1)) {
            -webkit-transform: scale(0.8, 0.8);
            -moz-transform: scale(0.8, 0.8);
            -ms-transform: scale(0.8, 0.8);
            -o-transform: scale(0.8, 0.8);
            transform: scale(0.8, 0.8);
        }

        .close {
            position: absolute;
            right: 40px;
            top: 21px;
            z-index: 1010;
            overflow: hidden;
            text-indent: -1000px;
            background: transparent url(../images/close.png) no-repeat right top;
            width: 16px;
            height: 16px;
            text-decoration: none;
            border: none;
        }

        h2 {
            font-family: sans-serif;
            font-size: 1.7em;
            margin: 0 35px;
            padding: 10px 0 8px;
            border-bottom: 1px solid #e5e6e6;
            color: #000;
        }
    }
}
