﻿@import "mixins.less";

.videoThumbnail {
    .cover();
    position: relative;
    width: 350px;
    height: 150px;
    .videoIndicator(#000);
}

.imageThumbnail {
    .cover();
}

.imageThumbnail.centeredThumbnail {
    background-position: center !important;
}

.breadcrumb {
    padding: 2rem 15px 1rem;
    margin: 0;
    list-style: initial;
    background-color: initial;
    border-radius: initial;
    .pageLayout();

    @media screen and (min-width:@tabletWidth) and (max-width:(@desktopWidth - 1)) {
        padding-left: 35px;
        padding-right: 35px;
    }

    li {
        font-size: 0.9rem;
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: inline;

        &.active {
            font-weight: bold;
        }

        a {
            text-decoration: none;
        }
    }
}

.latestNews {
    article {
        transition: transform @transitionSpeed;

        &:hover, &active {
            transform: scale(1.1,1.1);
        }
    }
}

.latestNewsBlocks {
    article {
        transition: transform @transitionSpeed;

        &:hover, &active {
            transform: scale(1.06,1.06);
        }
    }
}

.collapse {
    display: none;
}

.collapse.in {
    display: block;
}

tr.collapse.in {
    display: table-row;
}

tbody.collapse.in {
    display: table-row-group;
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    -webkit-transition-property: height, visibility;
    -o-transition-property: height, visibility;
    transition-property: height, visibility;
    -webkit-transition-duration: 0.35s;
    -o-transition-duration: 0.35s;
    transition-duration: 0.35s;
    -webkit-transition-timing-function: ease;
    -o-transition-timing-function: ease;
    transition-timing-function: ease;
}

#frmContactMessage, #contactThanks, #frmVolunteerRole, #signup {
    background-color: #fff;
    display: block;
    padding: 25px 20px;
    box-shadow: @boxShadowStandard;
}

#frmVolunteerSearch {
    &.results {
        h3 {
            font-size: @fontSizeLarge;
            font-weight: 900;
            margin-bottom: 2rem;
        }

        .btns {
            margin-top: 3rem;

            button {
                background-color: @buttonColour;
            }
        }
    }
}

#frmContactMessage, #frmVolunteerRole, #signup {
    > div:last-of-type {
        margin-bottom: -45px;
        margin-top: 30px;
    }

    > div {
        position: relative;
    }

    @media screen and (min-width: @tabletWidth) {
        padding: 25px 30px;

        > div {
            clear: both;

            &.inline {
                display: inline-block;
                margin-right: 1rem !important;
            }

            &.last {
                margin-right: 0 !important;
                float: right;
            }

            &:last-of-type {
                margin-top: 40px;
                margin-bottom: -45px;
            }
        }
    }
}

#frmVolunteerRole {
    max-width: 535px;
    box-sizing: border-box;
}

#frmVolunteerRole, #frmContactMessage {
    legend {
        font-size: 1.35rem;
        font-weight: bold;
    }
}

#frmContactMessage {
    legend {
        padding-top: 1em;
    }
}

#frmHeaderBranchFinder {
    > p {
        margin-top: 1rem;
        margin-bottom: 0;
    }
}

#eventSearchForm, #nearYouSearchForm, #searchFilters {
    display: inline-block;

    label.radio {
        cursor: pointer;
    }

    .padded-bottom {
        padding-bottom: 1rem;
    }

    .search-location-heading {
        font-size: 1.1em;
    }

    .geoSearchButton2 {
        background-color: #007eb6;

        &:hover {
            color: #ffffff;
        }
    }

    section#categoryList {
        margin-bottom: 2rem;

        h2 {
            font-size: @fontSizeLarge;
            margin-bottom: 1rem;
        }

        li {
            margin-left: 0;
            list-style-type: none;
            overflow: hidden;
            display: inline-block;

            input[type=checkbox] {
                visibility: hidden;
                position: absolute;
                left: -100px;

                + span {
                    background-color: @beige;
                    color: @black;
                    display: inline-block;
                    padding: 11px 15px;
                    font-weight: bold;
                    font-size: @fontSizeBig;
                    margin-right: 10px;
                    min-width: 182px;
                    box-sizing: border-box;
                    text-align: center;



                    @media screen and (max-width:(@tabletWidth - 1)) {
                        min-width: 100%;
                    }
                }

                &:checked {
                    + span {
                        background-color: @submitButtonBackground;
                        color: @white;
                    }
                }
            }
        }
    }

    @media screen and (max-width: (@tabletWidth - 1)) {
        div.inline {
            display: block;
        }

        section#categoryList {
            ul {
                display: flex;
                margin-right: -10px;
                flex-wrap: wrap;
                margin-bottom: -10px;
            }

            li {
                flex: 1 1 40%;
                margin-right: 10px;
                margin-bottom: 10px;
                min-width: 140px;

                span {
                    width: 100%;
                }
            }
        }
    }

    select {
        width: 170px;
        position: relative;
        z-index: 1;
    }

    &.results {
        #geoMessages {
            display: none;
        }
    }

    #geoMessages {
        margin-top: 1.2rem;
    }

    @media screen and (max-width:(@tabletWidth - 1)) {
        .inline.postcode {
            margin-bottom: 2.1rem;
        }
    }

    .geoSearchButton {
        background-color: @buttonColour;
    }

    .btns {
        margin: 3rem 0;
    }
}

#nearYouSearchForm {
    display: block;
}

#eventSearchForm2 {
    display: inline-block;
    //width: 40%;
    top: -35px;
    position: relative;
}

.event {
    aside.categories {
        h1 {
            display: inline;
        }

        h3{
            margin-bottom: unset;
        }
    }
}

.EventIndex {
    .btn-blue {
        &:hover {
            color: #ffffff;
        }
    }

    #results {
        .filters {
            display: inline-block;
            //width: 55%;
            //top: 5px;
            position: relative;

            div.inline {
                display: inline;
                margin-left: 10px;

                @media screen and (max-width: @mediumPhoneWidth) {
                    display: block;
                    margin-top: 1rem;
                }
            }

            .btn-view {
                color: unset;
                background-color: #ededed;
                border: none;
                border-radius: unset;

                &:not(:first-of-type) {
                    margin-left: -4px;
                }
            }

            .btn-view.active, .btn-view:hover {
                color: #fff;
                background-color: #002664;
            }

            @media screen and (max-width: 768px) {
                display: block;
                width: 100%;
                margin-bottom: 1rem;
                //top: -35px;

                #sort {
                    right: 0;
                }
            }
        }

        #eventSearchForm2 {
            @media screen and (max-width: 768px) {
                width: 100%;
                top: -10px;
            }

            @media screen and (max-width: 540px) {
                top: -25px;

                div.inline {
                    margin-right: unset !important;
                }
            }
        }

        .btns.refresh {
            @media screen and (max-width: 540px) {
                //display: block;
            }
        }

        .geoSearchButton {
            background-color: #007eb6;
            margin-top: 33px;
            position: relative;
        }

        #eventResults {
            //top: -40px;
            position: relative;
            margin-bottom: -40px;
        }

        .geoLink {
            margin-top: 10px;
        }
    }

    .thumbLinks {
        article > a:first-of-type {
            padding: 25px 25px 80px;
        }

        span.more {
            display: inline-block;
            text-decoration: none;
            font-weight: bold;
            outline: none;
            position: absolute;
            bottom: 20px;
            color: #ffffff;
            box-sizing: border-box;
            overflow: hidden;
            border: 2px solid #ffffff;
            border-radius: 50px;
            background: transparent url(../images/arrow-right.png) no-repeat right 10px center;
            background-size: 20px auto;
            height: 44px;
            width: auto;
            max-width: 44px;
            min-width: 44px;
            transition: max-width 0.25s, background-color 0.25s, border-color 0.25s, transform 0.25s, background-image 0.25s;
            transition-delay: 0.25s;
            background-color: #e00018;
            border-color: #e00018;
            max-width: 350px;

            span {
                transition: opacity 0.25s;
                transition-delay: 0.25s;
                float: right;
                padding: 10px 45px 0 20px;
                white-space: nowrap;
                text-align: right;
            }
        }

        &.mob {
            display: none;

            @media screen and (min-width: 415px) and (max-width:(@desktopWidth - 1)) {
                .text {
                    max-width: 100%;
                }
            }

            @media screen and (max-width: 953px) {
                display: block;
            }
        }

        &.desk {
            @media screen and (max-width: 953px) {
                display: none;
            }
        }
    }

    #featuredEvent {
        .desktopFlex {

            @media screen and (min-width:@tabletWidth) {
                .date {
                    width: 95px;
                }

                .location {
                    width: 216px;
                }
            }
        }
    }
}
/*Arrows used to scroll through paged results*/
.resultsSliders {
    display: none;
    position: absolute;
    top: 48%;
    left: 0;
    width: 100%;

    button {
        background: transparent url() no-repeat center center;
        overflow: hidden;
        text-indent: -100px;

        &.forward {
            float: right;
            background-image: url(../images/forward-arrow.svg);
        }

        &.back {
            background-image: url(../images/back-arrow.svg);
        }
    }

    @media screen and (min-width: @tabletWidth + 90) {
        display: block;
    }
}

.expander {
    margin-bottom: 30px;

    button {
        padding-right: 55px;
        padding-left: 28px;
        text-align: left;
        box-sizing: border-box;
        background: @lightBlue url(../images/arrow-down.svg) no-repeat right 25px center;

        &.open {
            background-image: url(../images/arrow-up.svg);
        }

        &:focus {
            transform: none;
        }

        &:hover {
            transform: @buttonHoverTransform;
        }
    }
}

.btns.expander {
    & + * {
        display: none;
    }
}

dl.infoBlocks {
    margin: 1rem 15px 1rem;

    dt, dd {
        display: inline-block;
        width: 50%;
        font-size: @fontSizeBig;
        font-weight: bold;
        padding: 10px 15px;
        text-align: center;
        box-sizing: border-box;
        margin-bottom: 15px;
    }

    dt {
        background-color: @beige;
    }

    dd {
        background-color: @deepBlue;
        color: @white;
    }

    @media screen and (min-width:@tabletWidth) {
        display: flex;

        dt, dd {
            flex: 1 1 23%;
        }

        dd:nth-of-type(1) {
            margin-right: 7%;
        }
    }
}

ul.tabs {
    background-color: @white;
    padding: 8px 11px;
    display: flex;
    font-size: @fontSizeLarge;
    margin: 1rem 0;

    li {
        margin: 0;
        list-style-type: none;
        padding: 18px 20px;
        box-sizing: border-box;
        flex: 1 1 50%;
        font-weight: bold;
        text-align: center;
        cursor: pointer;

        &.selected {
            color: @white;
            background-color: @deepBlue;
        }

        @media screen and (max-width:(@tabletWidth - 1)) {
            span {
                display: block;
            }
        }
    }
}

ol.amounts {
    display: flex;
    font-size: @fontSizeBigger;
    margin-left: -10px;

    li {
        margin-left: 10px;
        list-style-type: none;
        padding: 15px 20px;
        flex: 1 1 30%;
        box-sizing: border-box;
        font-weight: bold;
        text-align: center;
        cursor: pointer;
        color: @white;
        background-color: @lightBlue;

        &.selected {
            background-color: @deepBlue;
        }

        .enlargeOnHover();
    }
}

.date, .location, .phone, .email, .share, .date2, .type {
    font-weight: 900;
    display: inline-block;
    margin-right: 50px;
    background: transparent url() no-repeat left center;
    height: 30px;
    padding-top: 10px;

    span {
        display: block;
        font-weight: normal;
        margin-top: 5px;
    }
}

.amountField {
    border: 2px solid @lightBlue;
    padding-left: 57px;
    background-color: @lightBlue;
    position: relative;
    margin-bottom: 1rem;

    &:before {
        content: '\00a3';
        color: @white;
        position: absolute;
        left: 20px;
        top: 0.8rem;
        font-size: @fontSizeHuge;
        font-weight: bold;
    }

    input {
        border: none;
        width: 100%;
        height: 55px;
        box-sizing: border-box;
        padding: 0 30px;

        @media screen and (min-width:@tabletWidth) {
            padding: 0 20px;
        }
    }
}

p.resultsCount {
    font-size: @fontSizeBig;
    font-weight: bold;
    margin-bottom: 2rem;
}

.contact-info {
    p {
        font-size: 18px;
        line-height: 22px;

        a {
            color: #127EB6;
        }
    }

    .box {
        margin-bottom: 20px;
    }
}

.date {
    padding-left: 40px;
    background-image: url(../images/calendar.svg);
}

.date2 {
    padding-left: 40px;
    background-image: url(../images/calendar2.svg);
}

.phone {
    padding-left: 40px;
    background-image: url(../images/phone.svg);
}

aside.phone {
    background: none;
}

.email {
    padding-left: 40px;
    background-image: url(../images/email2.svg);
}

.share {
    padding-left: 40px;
    background-image: url(../images/share.svg);
    margin-bottom: 0;
}

.location {
    padding-left: 30px;
    background-image: url(../images/location-marker.svg);
}

.type {
    padding-left: 40px;
    background-image: url(../images/local_offer_black_24dp.svg);
}

.geoLink {
    color: @geoLinkColour;
    padding-left: 36px;
    background: transparent url(../images/target.svg) no-repeat left center;
    min-height: 27px;
    display: inline-block;
    box-sizing: border-box;
    padding-top: 0.1rem;
}

ol.pagination {
    list-style-type: none;
    text-align: center;
    display: block;
    margin-bottom: 45px;

    li {
        display: inline-block;
        font-size: 1.25rem;
        margin: 0;
        vertical-align: top;

        &.btn {
            a {
                background: transparent url() no-repeat center center;
                text-indent: -1000px;
                overflow: hidden;
                position: relative;
                top: -1px;
            }

            &.first a {
                background-image: url(../images/pagination-first.svg);
            }

            &.prev a {
                background-image: url(../images/pagination-prev.svg);
            }

            &.next a {
                background-image: url(../images/pagination-next.svg);
            }

            &.last a {
                background-image: url(../images/pagination-last.svg);
            }
        }

        &.current {
            font-weight: 900;

            a {
                background-color: @paginationCurrentPageBackgroundColour;
                color: @paginationCurrentPageColour
            }
        }

        &.dsk {
            display: none;
        }

        a {
            text-decoration: none;
            padding: 5px 10px;
            box-sizing: border-box;
            width: 37px;
            height: 37px;
            display: inline-block;
        }

        @media screen and (min-width: @tabletWidth) {
            &.dsk {
                display: inline-block;
            }

            &.mob {
                display: none;
            }
        }
    }
}


#paymentMethodSelector {

    > div, > button {
        width: 100%;
        display: block;
        margin-bottom: 15px;
        box-sizing: border-box;
        transform: none;
        vertical-align: top;
    }

    button {
        box-sizing: border-box;
        width: 100%;
        border-radius: 4px;
        height: 50px;
        transform: none;
    }

    #btnApplePay {
        min-width: 200px;
    }

    #btnGooglePay {
        button {
            min-width: 200px;
            margin-bottom: 0;
        }
    }

    #btnPP {
        background: @paypalColour url(../images/paypal.png) no-repeat center center;
        text-indent: -1000px;
        min-width: 350px;
    }

    #btnCC {
        background-color: @black;
        min-width: 300px;
        font-weight: 900;
    }

    @media screen and (min-width: @tabletWidth) {
        > div, > button {
            width: auto;
            display: inline-block;
            margin-right: 15px;
        }

        button {
            width: auto;
        }
    }

    @media screen and (max-width: @smallPhoneWidth) {
        #btnPP, #btnCC {
            min-width: 290px;
        }
    }
}

#videoModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    display: none;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

#videoModal iframe {
    width: 900px;
    height: 75%;
    box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.3);
}


.close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 65px;
    color: white;
    line-height: 40px;
    cursor: pointer;
    z-index: 1000;
}

.modal {
    max-width: 100% !important;
    padding: 15px 15px !important;
}

body .blocker {
    background-color: @knockbackColour;
    z-index: 1000;
}


.ui-datepicker.ui-widget {
    z-index: 200 !important;
}

table {
    tr {
        td {
            padding: 1% 0.5%;
        }
    }
}

#LiveChat1 {
    cursor: pointer;
}

.header-btn-chat {
    display: inline-block;
    background-color: #022664 !important;
    border-radius: 0 !important;
    font-size: 16px !important;
    line-height: 28px !important;
    color: #fff !important;
    font-weight: bold;
    padding: 1px 5px !important;
    text-align: center;
    width: 129px;
    //margin: 20px auto 20px;
    //padding-top: 3px !important;
    cursor: pointer;
    text-decoration: none;
    margin-left: 25px;
    right: 0;

    img {
        display: inline-block;
        width: 28px;
        top: 10px;
        position: relative;
        margin-right: 5px;
    }

    @media screen and (max-width: 376px) {
        width: 81px;
        margin-left: 8px;
    }

    @media screen and (max-width: 414px) and (min-width: 377px) {
        width: 103px;
    }
}

.mobile-donation-button {
    display: none;

    @media screen and (max-width: 414px) {
        display: block;
        position: fixed;
        z-index: 500;
        bottom: 0;
        width: 100%;

        a {
            display: block;
            //width: 100%;
            background-color: #E00018;
            color: #fff;
            text-decoration: none;
            font-size: 16px;
            line-height: 16px;
            font-weight: bold;
            text-align: center;
            padding: 20px;
        }
    }
}

#bannerBar {
    z-index: 201;
    padding: 10px 10px 10px 10px;
    max-height: 77px;
    color: #022664;
    //font-weight: bold;
    overflow: hidden;

    div {
        overflow: hidden;
    }

    a {
        color: #022664;
    }

    &.top {
        top: 0;
    }

    &.bottom {
        position: fixed;
        bottom: 0;
    }

    em {
        font-style: italic;
    }

    strong {
        font-weight: bold !important;
    }

    @media screen and (max-width: @desktopWidth) {
        min-height: 40px;
        height: auto;
        max-height: initial;

        div {
            overflow: initial;
            white-space: initial;
        }
    }
}

.nav-pinned {
    #bannerBar {
        position: fixed;
        z-index: 201;
    }
}

figure.news-figure {
    width: fit-content;
    border: 3px solid #007EB6;

    &.left {
        margin-right: 15px
    }

    &.right {
        margin-left: 15px
    }

    &.center {
        margin: 0 auto;
        margin-bottom: 20px;
    }

    img.desktopImage {
        width: 348px;
        display: initial;
    }

    img.mobImage {
        width: 100%;
        display: none;
    }

    @media screen and (max-width:(@tabletWidth)) {
        width: fit-content;
        border: 3px solid #007EB6;
    }

    @media screen and (max-width:(@smallPhoneWidth)) {
        width: 100%;
        border: 3px solid #007EB6;

        img.desktopImage {
            display: none;
        }

        img.mobImage {
            width: 100%;
            display: initial;
        }
    }
}
.carouselNc {
    clear: both;
    padding-bottom: 10px;
    padding-top: 10px;
    .carousel {
        border: 3px solid #007EB6;
        width: 100%;
        //height: 530px;

        .img-holder {
            background-color: #70777e;
            height: 376px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        img {
            height: auto !important;
            max-height: 376px;
        }

        .caption {
            margin-top: 10px;
            margin-left: 10px;
            color: #000000;

            h3 {
                font-weight: bold;
                font-size: 18px;
            }

            p {
                text-align: left;
                font: 16px;
                letter-spacing: 0px;
            }
        }

        .carousel-pagination {
            font-weight: bold;
            position: absolute;
            top: 387px;
            right: 20px;
            z-index: 15;
            padding-left: 0;
            margin-left: -30%;
            text-align: center;

            .pagination {
                text-align: center;
                font: 18px;
                letter-spacing: 0px;
                color: #000000;
                opacity: 1;
                margin: unset;
            }

            .nav-left {
                display: inline-block;
                width: 10px;
                height: 12px;
                font-size: 30px;
                opacity: 1;
                background: transparent url(../images/pagination-previous-blue.svg) 0% 0% no-repeat padding-box;
            }

            .nav-right {
                display: inline-block;
                width: 10px;
                height: 12px;
                font-size: 30px;
                opacity: 1;
                background: transparent url(../images/pagination-next-blue.svg) 0% 0% no-repeat padding-box;
            }
        }

        @media (max-width:(@mediumTabletWidth)) {
            width: 100%;
            overflow: hidden;

            .img-holder {
                background-color: #70777e;
                height: 299px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            img {
                height: auto !important;
                max-height: 299px;
            }

            .carousel-pagination {
                position: absolute;
                top: 310px;
                right: 20px;
                z-index: 15;
                padding-left: 0;
                margin-left: -30%;
            }
        }

        @media (max-width:(@tabletWidth)) {
            width: 100%;

            .img-holder {
                background-color: #70777e;
                height: 299px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            img {
                height: auto !important;
                max-height: 299px;
            }

            .carousel-pagination {
                position: absolute;
                bottom: 10px;
                top: unset;
                z-index: 15;
                padding-left: 0;
                left: 0;
                right: 0;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}
